import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import Navbar from "../components/Navbar";
import PatientData from "../components/PatientData";
import ProgressBar from "../components/ProgressBar";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Reports = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const type = searchParams.get("type");
  const patientData = useSelector(
    (state: RootState) => state.OsteroCheck.patientData
  );
  return (
    <div className="flex justify-center h-screen w-screen py-4 px-8 sm:px-4 sm:py-2">
      <div className="h-full w-full">
        <div className="h-1/12">
          <Navbar />
        </div>

        {patientData.loading ? (
          <div
            className=" bg-white pt-4  grid   gap-x-6 h-full justify-center items-center shadow-md rounded
     rounded-2xl  p-8 bg-clean-white 
   border box-border  shadow-[4px_4px_4px_rgba(0,0,0,0.2)] border-gray-300 "
          >
            <div
              className="text-logo-blue inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
              role="status"
            >
              <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Loading...
              </span>
            </div>{" "}
          </div>
        ) : (
          <div className=" bg-white pt-4  grid grid-cols-4 grid-rows-2  gap-x-6 h-11/12  sm:flex sm:flex-col">
            <div className=" grid col-span-1 row-span-1 h-96">
              <PatientData />
              {/* <a href={patientData.data.report_link} target="_blank" download className="bg-logo-blue text-center h-10 cursor-pointer mt-4 hover:bg-gray-300 hover:text-black text-white font-bold py-2 px-4 rounded" >
                Download Report
              </a> */}
             
            </div>
            <div
              className="col-span-3  row-span-2 shadow-md rounded
     rounded-2xl  p-8 bg-clean-white 
   border box-border  shadow-[4px_4px_4px_rgba(0,0,0,0.2)] border-gray-300 overflow-y-auto sm:p-4"
            >
              <div className="flex flex-col gap-4">
                <div className="flex text-dark-blue-60 gap-2">
                  <img
                    className=" max-w-full overflow-hidden max-h-full"
                    alt=""
                    src="/img/back.svg"
                  />
                  <div
                    className="font-semibold inline-block"
                    onClick={() => {
                      if (type === "completed") navigate("/list");
                      else navigate("/upload-scan");
                    }}
                  >
                    Back
                  </div>
                </div>
                {type !== "completed" && (
                  <ProgressBar
                    patient_info={{ isDone: true, loading: 100 }}
                    upload_scan={{ isDone: true, loading: 100 }}
                    report={{ isDone: true, color: "green" }}
                  />
                )}

                <p className="font-bold mt-5 text-left text-xl">Key Images</p>
              </div>

              <div className="grid grid-cols-1 gap-16 pt-4 ">
                {patientData.data.json?.data.map((val, index) => (
                  <div key={index}>
                    <p className="font-bold text-red-600 text-center text-center text-2xl pb-1 ">
                      {val.knee_side} Knee
                    </p>
                    <div className="flex  justify-around items-center pt-8 gap-4 sm:flex-col">
                      <div className="">
                        <img
                          src={val.url}
                          alt=""
                          className=" max-h-80 max-w-80"
                        />
                      </div>
                      <div className="flex flex-col">
                        <p className="font-bold my-4 text-xl">Findings</p>
                        <div className="self-end">
                          {Object.keys(val.findings).map((value, index) => (
                            <div
                              key={index}
                              className="grid grid-cols-2 pb-1 justify-end items-end"
                            >
                              <p className="pr-2">{value}</p>
                              <p className=""> - {val.findings[value]}</p>
                            </div>
                          ))}
                        </div>
                        {val.grade ? (
                          <p className="text-2xl font-bold text-red-600 pt-4">
                            Osteoarthritis Grade {val.grade}
                          </p>
                        ) : (
                          <p className="text-2xl font-bold text-green-600">
                            No Abnormalities
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="py-8">
                <p className="font-bold pb-1 text-xl">Observation</p>
                <ul className="list-disc pl-4  text-gray-700">
                  {patientData.data.json?.observations &&
                    patientData.data.json?.observations.map((data, index) => (
                      <li key={index}>{data}</li>
                    ))}
                </ul>
              </div>

              <div className="">
                <p className="font-bold pb-1  text-xl">Impression</p>
                <ul className="list-disc pl-4 font-bold text-gray-700">
                  {patientData.data.json?.impression &&
                    patientData.data.json?.impression.map((data, index) => (
                      <li key={index}>{data}</li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Reports;
