import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../utils/firebase";
import { logout } from "../redux/AuthSlice";
import { useDispatch } from "react-redux";

const Navbar = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleProfile = () => {
    setMenuVisible(!isMenuVisible);
  };
  return (
    <div className="flex justify-between items-center">
      <img
        onClick={() => navigate("/list")}
        className=" object-contain cursor-pointer w-28 h-16 sm:w-16 sm:h-10"
        alt=""
        src="/img/logo.png"
      />

      <div className="flex  items-center">
        <div className="text-sm px-2 sm:px-0">Need Help ?</div>

        <div className="flex px-2 gap-2">
          {/* <img
            className=" object-contain cursor-pointer pr-1"
            alt=""
            src="/img/whatsup.svg"
          /> */}
          <img width="30" height="30" src="https://img.icons8.com/color/48/gmail-new.png" alt="gmail-new"/>
          <p className="text-neutral-500 font-bold sm:text-[10px] sm:flex sm:items-center">product@5cnetwork.com</p>
        </div>
        <div>
          <img
            style={{ borderRadius: "50%" }}
            className=" object-contain cursor-pointer w-16 h-16 p-1 sm:w-10 sm:h-10"
            alt=""
            src="/img/profile.png"
            onClick={handleProfile}
          />

          {isMenuVisible && (
            <div
              className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button">
              <div className="py-1" role="none">
                <a
                  href="#"
                  className="text-gray-700 block px-4 py-2 border-b text-sm"
                  role="menuitem"
                  id="menu-item-0"
                  onClick={() => navigate("/profile")}>
                  Profile
                </a>
                <button
                  type="button"
                  className="text-gray-700 block w-full px-4 py-2 text-left text-sm"
                  role="menuitem"
                  id="menu-item-3"
                  onClick={() => {
                    dispatch(logout());
                    auth.signOut();
                  }}>
                  Log Out
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
