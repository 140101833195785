import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
const baseUrl = "https://osseus-backend.5cnetwork.com/"

export type StudiesType = {
  order_id: string;
  patient_name: string;
  patient_age: number;
  patient_sex: string;
  date: string;
  referring_doctor: string;
};

export type PatientType = {
  name: string;
  age: number;
  sex: string;
  phone_no: string;
  ref_doc: string;
  history: string;
  order_id?: string;
  study_link?: string;
  report_link?: string;
  json?: {
    organ: string;
    data:
    | [
      url: string,
      knee_side: string,
      findings: {
        "Reducing Joint Space": string;
        Osteophytes: string;
        Sclerosis: string;
        "Post-OP": string;
        Alignment: string;
        "Soft Tissues": string;
      },
      grade: string
    ]
    | any[];
    observations: string[];
    impression: string[];
  };
};

export type StudiesOutputType = {
  message: string;
  studies: StudiesType[];
};

export const requestStudiesList = createAsyncThunk<
  any,
  void,
  { rejectValue: string }
>("requestStudiesList", async (_, thunkAPI) => {
  try {
    const response = await fetch(
      `${baseUrl}/study/get-studies`,
      {
        method: "GET"
      }
    );
    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.log(error);
    return thunkAPI.rejectWithValue("Failed to fetch studies list");
  }
});

export const requestReport = createAsyncThunk<
  any,
  string,
  { rejectValue: string }
>("requestReport", async (resData, thunkAPI) => {
  try {
    console.log(resData);
    const response = await fetch(
      `${baseUrl}/study/get-study-info?order_id=${resData}`,
      {
        method: "GET"
      }
    );
    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.log(error);
    return thunkAPI.rejectWithValue("Failed to fetch studies list");
  }
});

export const downloadReport = createAsyncThunk<
  { "report_link": string },
  string | undefined,
  { rejectValue: string }
>("downloadReport", async (resData, thunkAPI) => {
  try {
    console.log(resData);
    const response = await fetch(
      `${baseUrl}/report/get-report?order_id=${resData}`,
      {
        method: "GET"
      }
    );
    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.log(error);
    return thunkAPI.rejectWithValue("Failed to fetch studies list");
  }
});

export const requestAddPatient = createAsyncThunk<
  any,
  { patient_data: PatientType; formData: FormData },
  { rejectValue: string }
>("requestAddPatient", async (data, thunkAPI) => {
  try {
    data.formData.append("patient_name", data.patient_data.name);
    data.formData.append("referring_doctor", data.patient_data.ref_doc);
    data.formData.append("primary_doctor", "demo");
    data.formData.append("patient_age", data.patient_data.age.toString());
    data.formData.append("patient_sex", data.patient_data.sex);
    data.formData.append("patient_mobile", data.patient_data.phone_no);
    data.formData.append("patient_history", data.patient_data.history);

    console.log(data.formData.getAll("patient_age"));

    const response = await fetch(
      `${baseUrl}/study/get-osteocheck`,
      {
        method: "POST",
        body: data.formData
      }
    );
    const resData = await response.json();
    if (resData.details) {
      return "Please fill the patient details properly";
    }
    console.log({ ...resData, json: JSON.parse(resData.json)[0] });

    return { ...resData, json: JSON.parse(resData.json)[0] };
  } catch (error) {
    console.log(error);
    return thunkAPI.rejectWithValue("Failed to fetch studies list");
  }
});

interface OsteroCheckSliceState {
  patientList: {
    data: StudiesOutputType;
    loading: boolean;
    error: string | null;
  };
  patientData: { data: PatientType; loading: boolean; error: string | null };
  report: { loading: boolean; error: string | null };
  downloadReport: { loading: boolean; error: string | null };
}

const initialState: OsteroCheckSliceState = {
  patientList: {
    data: { message: "", studies: [] },
    loading: false,
    error: null
  },
  patientData: {
    data: {
      name: "",
      age: 0,
      sex: "",
      phone_no: "",
      ref_doc: "",
      history: "",
      study_link: "",
      report_link: "",
      json: { organ: "", data: [], observations: [], impression: [] }
    },
    loading: false,
    error: null
  },
  report: { loading: false, error: null },
  downloadReport: { loading: false, error: null }
};

export const OsteroCheckSlice = createSlice({
  name: "requestStudiesList",
  initialState,
  reducers: {
    addPatient: (state, action: PayloadAction<PatientType>) => {
      state.patientData.data = { ...state.patientData.data, ...action.payload };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestStudiesList.pending, (state) => {
        state.patientList.loading = true;
        state.patientList.error = null;
      })
      .addCase(requestStudiesList.fulfilled, (state, action) => {
        state.patientList.loading = false;
        state.patientList.data = action.payload;
      })
      .addCase(requestStudiesList.rejected, (state, action) => {
        state.patientList.loading = false;
        state.patientList.error =
          action.error.message || "Something went wrong";
      })

      // =====================================================

      .addCase(requestReport.pending, (state) => {
        state.patientData.loading = true;
        state.patientData.error = null;
      })
      .addCase(requestReport.fulfilled, (state, action) => {
        state.patientData.loading = false;
        state.patientData.data.name = action.payload.studies[0].patient_name;
        state.patientData.data.sex = action.payload.studies[0].patient_sex;
        state.patientData.data.history = action.payload.studies[0].history;
        state.patientData.data.age = action.payload.studies[0].patient_age;
        state.patientData.data.report_link = action.payload.studies[0].report_link
        state.patientData.data.phone_no =
          action.payload.studies[0].patient_mobile;
        state.patientData.data.ref_doc =
          action.payload.studies[0].referring_doctor;

        state.patientData.data.order_id = action.payload.studies[0].order_id;
        state.patientData.data.json = action.payload.studies[0].json[0];
      })
      .addCase(requestReport.rejected, (state, action) => {
        state.patientData.loading = false;
        state.patientData.error =
          action.error.message || "Something went wrong";
      })

      // =====================================================

      .addCase(requestAddPatient.pending, (state) => {
        state.report.loading = true;
        state.patientData.error = null;
      })
      .addCase(requestAddPatient.fulfilled, (state, action) => {
        state.report.loading = false;
        state.patientData.data.json = action.payload.json;
        state.patientData.data.study_link = action.payload.study_link;
        state.patientData.data.report_link = action.payload.report_link;
      })
      .addCase(requestAddPatient.rejected, (state, action) => {
        state.report.loading = false;
        state.patientData.error =
          action.error.message || "Something went wrong";
      })

      // =====================================================

      .addCase(downloadReport.pending, (state) => {
        state.report.loading = true;
        state.patientData.error = null;
      })
      .addCase(downloadReport.fulfilled, (state, action) => {
        state.report.loading = false;
        state.patientData.data.report_link = action.payload.report_link;
      })
      .addCase(downloadReport.rejected, (state, action) => {
        state.report.loading = false;
        state.patientData.error =
          action.error.message || "Something went wrong";
      });
  }
});

export const { addPatient } = OsteroCheckSlice.actions;
export default OsteroCheckSlice.reducer;
